import React from "react"
import { Link , graphql } from "gatsby"
import Headerjawn from "../components/headerjawn"
import Layout from "../components/bloglayout"
import Footerjawn from "../components/footerjawn"

import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { tween } from "popmotion";

const Box = posed.div({
  hidden: { 
    opacity: [0,1], 
    left: '-100%',
    transition: props => tween({ ...props, duration: 0 })
  },
  visible: { 
    opacity: [1,0], 
    left: '100%',
    transition: props => tween({ ...props, duration: 1000 })
  },
})


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext

    return (
    <>
    <Headerjawn/> 

    <Layout>
      <section className="blog-cc">
        <h2>BLOG</h2> 
      
        <article className="full-post">        
          <h1>{post.frontmatter.title}</h1>        
          <section dangerouslySetInnerHTML={{ __html: post.html }} />      
        </article>  

          <ul>
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
          </ul>
        </section>

        <section className="blog-cc-side">
          <h2>I be a sidebar didebbebeb dibby doo</h2>
          <p>skfjssl ksldfkl lksjdfl lksjdf sd</p>
          <p>skfjssl ksldfkl lksjdfl lksjdf sd</p>
        </section>
    </Layout>

    <Footerjawn/>

    <TransitionState>
        {({ transitionStatus }) => {
            return (
                <Box
                  className="box"
                  pose={
                    ['entering', 'entered'].includes(transitionStatus)
                      ? 'hidden'
                      : 'visible'
                  }
                />
            )
        }}
    </TransitionState>
    <style
          dangerouslySetInnerHTML={{
            __html: `
            .box {
                background: #64fec3;
            }
        `,
          }}
    />
    
    </>
  )
}
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 200)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`